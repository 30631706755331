<template>
  <v-card
    flat
    width="100%"
    height="100%"
    class="pa-1 d-flex flex-row white--text text-caption font-weight-medium"
    color="grey darken-1"
    :style="isCancelled ? 'opacity: 0.5' : ''"
  >
    <div class="pl-1 pr-2 my-auto d-none d-md-flex" style="min-width: 40px">
      <v-avatar
        id="photo"
        :size="28"
        v-if="instructor"
        v-html="photoSVG"
        color="white"
      />
    </div>

    <div class="my-auto">
      <div class="d-flex flex-row">
        <div id="title" class="text-no-wrap text-truncate">
          {{ title }}
        </div>
        <div class="px-1 flex-shrink-1 font-weight-regular d-none d-md-flex">
          (<span id="duration">{{ duration }}</span
          >)
        </div>
      </div>
      <div
        id="instructor"
        v-if="instructor"
        class="text-truncate font-weight-regular"
      >
        {{ instructor }}
      </div>
    </div>

    <div class="ml-auto mb-auto d-none d-lg-flex">
      <v-tooltip top v-if="isInPerson">
        <template #activator="{ on, attrs }">
          <v-icon id="isInPerson" color="white" small v-bind="attrs" v-on="on">
            mdi-human-male-female
          </v-icon>
        </template>
        <span>{{ $t("in person") }}</span>
      </v-tooltip>
      <v-tooltip top v-if="isVirtual">
        <template #activator="{ on, attrs }">
          <v-icon id="isVirtual" color="white" small v-bind="attrs" v-on="on">
            mdi-access-point
          </v-icon>
        </template>
        <span>{{ $t("virtual") }}</span>
      </v-tooltip>
    </div>
  </v-card>
</template>

<script>
import Avatars from "@dicebear/avatars";
import sprites from "@dicebear/avatars-jdenticon-sprites";
export default {
  name: "StaffEventCard",
  props: {
    title: { type: String },
    duration: { type: Number },
    isInPerson: { type: Boolean },
    isVirtual: { type: Boolean },
    instructor: { type: String },
    isCancelled: { type: Boolean },
  },
  data: () => ({
    avatars: new Avatars(sprites, {}),
  }),
  computed: {
    photoSVG() {
      return this.avatars.create(this.instructor);
    },
  },
};
</script>
