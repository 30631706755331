<template>
  <v-card
    :disabled="disabled"
    @keydown.esc="$emit('close')"
    @keydown.enter.prevent="$emit('close')"
  >
    <v-toolbar dense flat color="grey lighten-3">
      <v-toolbar-title class="grey--text text--darken-2">
        <div class="d-flex flex-row">
          <div ref="title">{{ title }}</div>
          <div class="mx-2">
            (<span ref="duration">{{ duration }}</span
            >)
          </div>
        </div>
      </v-toolbar-title>
      <v-spacer />
      <v-toolbar-items>
        <v-menu offset-y>
          <template #activator="{ on, attrs }">
            <v-btn id="more" ref="menu" x-small icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-list dense>
              <v-list-item
                id="restore"
                ref="restore"
                v-if="canRestore"
                @click="$emit('restore')"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t("revert class") }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                id="cancel"
                ref="cancel"
                v-if="canCancel"
                @click="$emit('cancel')"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t("cancel class") }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider v-if="canRestore || canCancel"></v-divider>

              <v-list-item
                id="delete"
                ref="delete"
                v-if="canDelete"
                @click="$emit('delete')"
              >
                <v-list-item-content>
                  <v-list-item-title class="error--text">
                    <span>{{ $t("delete class") }}</span>
                    <span class="pl-1">(1)</span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                id="deleteSeries"
                ref="deleteSeries"
                v-if="canDeleteSeries"
                @click="$emit('deleteSeries')"
              >
                <v-list-item-content>
                  <v-list-item-title class="error--text">
                    <span>{{ $t("delete") }}</span>
                    <span v-if="count" class="pl-1">({{ count }})</span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
        <v-btn icon x-small @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <v-container>
      <v-row>
        <v-col>
          <v-slide-group
            mandatory
            :value="value"
            @change="(v) => $emit('input', v)"
          >
            <v-slide-item v-slot="{ active, toggle }">
              <v-btn
                ref="oneTime"
                id="one-time"
                small
                class="mx-2 text-none"
                :input-value="active"
                active-class="primary white--text"
                depressed
                @click="toggle"
                :disabled="!count"
                >{{ $t("one-time") }}</v-btn
              >
            </v-slide-item>
            <v-slide-item v-slot="{ active, toggle }">
              <v-btn
                ref="recurring"
                id="recurring"
                small
                class="mx-2 text-none"
                :input-value="active"
                active-class="primary white--text"
                depressed
                @click="toggle"
                :disabled="canDelete"
                >{{ $t("recurring") }}</v-btn
              >
            </v-slide-item>
          </v-slide-group>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <StaffAutocomplete
            id="instructor"
            :disabled="isMemberDisabled"
            autofocus
            :label="$t('assign instructor')"
            :items="staff"
            :value="member"
            @input="(instructor) => $emit('change', { instructor })"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import StaffAutocomplete from "./StaffAutocomplete";
export default {
  name: "StaffEventForm",
  components: {
    StaffAutocomplete,
  },
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: { type: Number },
    disabled: { type: Boolean },
    staff: { type: Array },
    title: { type: String },
    duration: { type: Number },
    instructor: { type: String },
    originalInstructor: { type: String },
    isCancelled: { type: Boolean },
    canRestore: { type: Boolean },
    canCancel: { type: Boolean },
    canDelete: { type: Boolean },
    canDeleteSeries: { type: Boolean },
    count: { type: Number, default: 0 },
  },
  data: () => ({
    searchInput: null,
  }),
  computed: {
    isMemberDisabled() {
      return !this.value && this.isCancelled;
    },
    member() {
      return !this.value ? this.instructor : this.originalInstructor;
    },
  },
};
</script>
