var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"disabled":_vm.disabled},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.$emit('close')},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.$emit('close')}]}},[_c('v-toolbar',{attrs:{"dense":"","flat":"","color":"grey lighten-3"}},[_c('v-toolbar-title',{staticClass:"grey--text text--darken-2"},[_c('div',{staticClass:"d-flex flex-row"},[_c('div',{ref:"title"},[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"mx-2"},[_vm._v(" ("),_c('span',{ref:"duration"},[_vm._v(_vm._s(_vm.duration))]),_vm._v(") ")])])]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({ref:"menu",attrs:{"id":"more","x-small":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-card',[_c('v-list',{attrs:{"dense":""}},[(_vm.canRestore)?_c('v-list-item',{ref:"restore",attrs:{"id":"restore"},on:{"click":function($event){return _vm.$emit('restore')}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t("revert class"))+" ")])],1)],1):_vm._e(),(_vm.canCancel)?_c('v-list-item',{ref:"cancel",attrs:{"id":"cancel"},on:{"click":function($event){return _vm.$emit('cancel')}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t("cancel class"))+" ")])],1)],1):_vm._e(),(_vm.canRestore || _vm.canCancel)?_c('v-divider'):_vm._e(),(_vm.canDelete)?_c('v-list-item',{ref:"delete",attrs:{"id":"delete"},on:{"click":function($event){return _vm.$emit('delete')}}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"error--text"},[_c('span',[_vm._v(_vm._s(_vm.$t("delete class")))]),_c('span',{staticClass:"pl-1"},[_vm._v("(1)")])])],1)],1):_vm._e(),(_vm.canDeleteSeries)?_c('v-list-item',{ref:"deleteSeries",attrs:{"id":"deleteSeries"},on:{"click":function($event){return _vm.$emit('deleteSeries')}}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"error--text"},[_c('span',[_vm._v(_vm._s(_vm.$t("delete")))]),(_vm.count)?_c('span',{staticClass:"pl-1"},[_vm._v("("+_vm._s(_vm.count)+")")]):_vm._e()])],1)],1):_vm._e()],1)],1)],1),_c('v-btn',{attrs:{"icon":"","x-small":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-container',[_c('v-row',[_c('v-col',[_c('v-slide-group',{attrs:{"mandatory":"","value":_vm.value},on:{"change":function (v) { return _vm.$emit('input', v); }}},[_c('v-slide-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-btn',{ref:"oneTime",staticClass:"mx-2 text-none",attrs:{"id":"one-time","small":"","input-value":active,"active-class":"primary white--text","depressed":"","disabled":!_vm.count},on:{"click":toggle}},[_vm._v(_vm._s(_vm.$t("one-time")))])]}}])}),_c('v-slide-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-btn',{ref:"recurring",staticClass:"mx-2 text-none",attrs:{"id":"recurring","small":"","input-value":active,"active-class":"primary white--text","depressed":"","disabled":_vm.canDelete},on:{"click":toggle}},[_vm._v(_vm._s(_vm.$t("recurring")))])]}}])})],1)],1)],1),_c('v-row',[_c('v-col',[_c('StaffAutocomplete',{attrs:{"id":"instructor","disabled":_vm.isMemberDisabled,"autofocus":"","label":_vm.$t('assign instructor'),"items":_vm.staff,"value":_vm.member},on:{"input":function (instructor) { return _vm.$emit('change', { instructor: instructor }); }}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }