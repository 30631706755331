<template>
  <Layout>
    <template #title>
      <ScheduleLabel :title="title" :range="range" :data-draft-title="title" />
    </template>
    <EventsLayout
      v-if="week !== null"
      :week="week"
      with-range
      @update:week="(week) => $router.push({ query: { week } })"
    >
      <template #default="{ attrs }">
        <StaffEventsView
          v-bind="attrs"
          readonly
          :min-box-width="minBoxWidth"
          :min-box-height="minBoxHeight"
          :max-box-width="maxBoxWidth"
          :max-box-height="maxBoxHeight"
        >
          <template #footer>
            <Footer />
          </template>
        </StaffEventsView>
      </template>
    </EventsLayout>
  </Layout>
</template>

<script>
import { doc, getDoc, where } from "@firebase/firestore";
import moment from "moment";
import { mapGetters } from "vuex";
import Layout from "@/components/draft/Layout";
import EventsLayout from "@/components/draft/EventsLayout";
import StaffEventsView from "@/components/draft/StaffEventsView";
import ScheduleLabel from "@/components/draft/ScheduleLabel";
import Footer from "@/components/home/Footer";

export default {
  name: "Draft",
  components: {
    Layout,
    EventsLayout,
    ScheduleLabel,
    StaffEventsView,
    Footer,
  },
  props: {
    minBoxWidth: { type: Number },
    maxBoxWidth: { type: Number },
    minBoxHeight: { type: Number },
    maxBoxHeight: { type: Number },
  },
  data: () => ({
    draftRef: null,
    week: null,
  }),
  computed: {
    ...mapGetters({
      title: "draft/title",
      range: "draft/range",
      totalWeeks: "draft/totalWeeks",
      weeks: "draft/weeks",
    }),
  },
  watch: {
    async week(index) {
      try {
        if (index === null || index in this.weeks) return;
        const d = moment(this.range?.beg)
          .startOf("isoWeek")
          .add(index, "weeks");
        const result = await this.$firebase.getDocs(
          this.$firebase.query(
            this.$firebase.collection(this.draftRef, "occurrences"),
            where("date", ">=", d.toDate()),
            where("date", "<=", d.endOf("isoWeek").toDate())
          )
        );
        const week = {};
        result.forEach((doc) => {
          week[doc.id] = Object.assign(doc.data(), {
            date: doc.get("date").toDate(),
          });
        });
        this.$store.dispatch("draft/setWeek", { week, index });
      } catch (err) /* istanbul ignore next*/ {
        // FIXME: remove this in Vue3
        this.$store.dispatch("alert/error", err.message);
        this.$firebase.log("error", {
          page_location: this.$route.fullPath,
          page_path: this.$route.path,
          err,
        });
        throw err;
      }
    },
  },
  /* istanbul ignore next */
  async beforeRouteEnter(to, from, next) {
    let draftResult;
    let rangeResult;
    let holidayResult;

    try {
      const baseRef = to.matched.find((r) => r.meta.ref).meta.ref(to);
      const draftRef = doc(baseRef, "drafts", to.params.draftId);
      draftResult = await getDoc(draftRef);
      if (!draftResult.exists() || !draftResult.get("isPublic")) {
        return next({ name: "404", params: [to.path] });
      }
      [rangeResult, holidayResult] = await Promise.all([
        getDoc(doc(draftRef, "pub/range")),
        getDoc(doc(draftRef, "pub/holidays")),
      ]);
    } catch (err) {
      return next({ name: "404", params: [to.path] });
    }
    return next(async ($vm) => {
      $vm.draftRef = draftResult.ref;
      await $vm.$store.dispatch("draft/setData", draftResult.data());
      if (!rangeResult.exists()) return;
      await $vm.$store.dispatch("draft/setRange", {
        beg: rangeResult.get("range.beg").toDate(),
        end: rangeResult.get("range.end").toDate(),
      });
      if (holidayResult.exists()) {
        await $vm.$store.dispatch(
          "draft/setHolidays",
          Object.values(holidayResult.get("holidays")).map((ev) => ({
            date: moment(ev.date).toDate(),
            title: ev.title,
          }))
        );
      }
      const week = parseInt(to.query.week);
      if (isNaN(week) || week < 0)
        return next({ path: to.path, query: { week: 0 } });
      if (week >= $vm.totalWeeks)
        return next({ path: to.path, query: { week: $vm.totalWeeks - 1 } });
      $vm.week = week;
    });
  },
  /* istanbul ignore next */
  beforeRouteUpdate(to, from, next) {
    const week = parseInt(to.query.week);
    if (isNaN(week) || week < 0) {
      return next({ path: to.path, query: { week: this.week ?? 0 } });
    }
    if (week >= this.totalWeeks) {
      return next({ path: to.path, query: { week: this.totalWeeks - 1 } });
    }
    this.week = week;
    return next();
  },
};
</script>
