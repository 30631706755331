<template>
  <div
    data-draft="staff"
    class="d-flex flex-column"
    :style="`
            width: ${viewport.clientWidth}px;
            height: ${viewport.clientHeight}px;
            overflow: auto`"
  >
    <div class="d-flex flex-column-reverse">
      <div class="d-flex flex-row">
        <template v-if="wdEvents.length">
          <div class="sticky" style="left: 0; z-index: 1">
            <Times
              ref="wdTimes"
              :times="wdTimes"
              :box-height="boxHeight"
              format="h:mm A"
            />
          </div>
          <StaffEvents
            ref="wdEvents"
            :readonly="readonly"
            :min-day="1"
            :max-day="5"
            :schedule-times="wdTimes"
            :events="wdEvents"
            :box-width="boxWidth"
            :box-height="boxHeight"
            :range="range"
            :staff="staff"
            @change="setOccurrence"
            @cancel="cancelOccurrence"
            @restore="restoreOccurrence"
            @delete="deleteOccurrence"
            @updateSeries="updateSeries"
            @deleteSeries="(id) => $emit('deleteSeries', id)"
          />
          <div v-if="weEvents.length" ref="spacer" style="min-width: 24px" />
        </template>

        <template v-if="weEvents.length">
          <div class="sticky" style="left: 0; z-index: 1">
            <Times
              ref="weTimes"
              :times="weTimes"
              :box-height="boxHeight"
              format="h:mm A"
            />
          </div>
          <StaffEvents
            ref="weEvents"
            :readonly="readonly"
            :min-day="6"
            :max-day="7"
            :schedule-times="weTimes"
            :events="weEvents"
            :box-width="boxWidth"
            :box-height="boxHeight"
            :range="range"
            :staff="staff"
            @change="setOccurrence"
            @cancel="cancelOccurrence"
            @restore="restoreOccurrence"
            @delete="deleteOccurrence"
            @updateSeries="updateSeries"
            @deleteSeries="(id) => $emit('deleteSeries', id)"
          />
        </template>
      </div>

      <div
        ref="header"
        class="d-flex flex-row sticky"
        style="top: 0; z-index: 1"
      >
        <template v-if="wdEvents.length">
          <Days
            ref="wdDays"
            :range="range"
            :days="weekdays"
            :holidays="holidays.slice(0, 5)"
            :box-width="boxWidth"
            :no-agenda="noAgenda"
          />
          <div v-if="weEvents.length" style="min-width: 24px" />
        </template>
        <template v-if="weEvents.length">
          <Days
            ref="weDays"
            :range="range"
            :days="weekends"
            :holidays="holidays.slice(5)"
            :box-width="boxWidth"
            :no-agenda="noAgenda"
          />
        </template>
      </div>
    </div>
    <v-card
      v-if="!events.length"
      id="no-events"
      class="d-flex flex-row ma-auto"
      flat
      tile
    >
      <div class="flex-grow-1" style="visibility: hidden" />
      <i18n path="no classes">
        <template #icon>
          <v-icon>mdi-plus</v-icon>
        </template>
      </i18n>
      <div class="flex-grow-1" style="visibility: hidden" />
    </v-card>
    <div class="d-flex flex-row" style="position: relative; top: 52px">
      <div class="flex-grow-1" style="visibility: hidden" />
      <slot name="footer" />
      <div class="flex-grow-1" style="visibility: hidden" />
    </div>
  </div>
</template>

<script>
import Times from "./Times";
import StaffEvents from "./StaffEvents";
import Days from "./Days";
import BaseEventsView from "./BaseEventsView";
export default {
  name: "StaffEventsView",
  extends: BaseEventsView,
  components: {
    Times,
    StaffEvents,
    Days,
  },
  props: {
    readonly: { type: Boolean },
    minBoxWidth: { type: Number, default: 0 },
    maxBoxWidth: { type: Number, default: Infinity },
    minBoxHeight: { type: Number, default: 0 },
    maxBoxHeight: { type: Number, default: Infinity },
    viewport: { type: Object, required: true },
    staff: { type: Array, required: true },
  },
  computed: {
    weekdays() {
      return this.days.slice(0, 5);
    },
    weekends() {
      return this.days.slice(5);
    },
    wdEvents() {
      return this.events.filter((ev) => ev.payload.when.day <= 5);
    },
    weEvents() {
      return this.events.filter((ev) => ev.payload.when.day > 5);
    },
    wdTimes() {
      return this.getTimes(this.wdEvents);
    },
    weTimes() {
      return this.getTimes(this.weEvents);
    },
    fillX() {
      const wdClientWidth = this.wdEvents.length ? 73 : 0;
      const weClientWidth = this.weEvents.length ? 73 : 0;
      const spacer = this.wdEvents.length && this.weEvents.length ? 24 : 0;
      return wdClientWidth + weClientWidth + spacer + 16;
    },
    fillY() {
      return 92;
    },
    boxWidth() {
      const days =
        (this.wdEvents.length ? 5 : 0) + (this.weEvents.length ? 2 : 0);
      const boxWidth =
        (this.viewport.clientWidth - this.fillX) / Math.max(days, 1);
      return Math.floor(
        Math.min(this.maxBoxWidth, Math.max(this.minBoxWidth, boxWidth))
      );
    },
    boxHeight() {
      const times = Math.max(this.wdTimes.length, this.weTimes.length);
      const boxHeight =
        (this.viewport.clientHeight - this.fillY) / Math.max(times, 1);
      return Math.floor(
        Math.min(this.maxBoxHeight, Math.max(this.minBoxHeight, boxHeight))
      );
    },
  },
  methods: {
    getTimes(events) {
      return events
        .map((ev) => ev.payload)
        .filter((ev, i, self) => {
          const matches = self
            .slice(0, i)
            .filter((fev) => fev.when.time === ev.when.time);
          return (
            !matches.length ||
            matches.some((fev) => fev.when.day === ev.when.day)
          );
        })
        .map((ev) => ev.when.time)
        .sort((a, b) => a - b);
    },
    updateSeries(id, data) {
      const ev = this.events.find((ev) => ev.templateId === id);
      const payload = Object.assign({ ...ev.payload }, data);
      this.$emit("updateSeries", payload, id);
    },
  },
};
</script>
