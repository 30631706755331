<template>
  <v-menu
    v-if="!readonly"
    absolute
    offset-y
    :value="menu"
    @input="reset"
    :close-on-content-click="false"
  >
    <template #activator="{ on, attrs }">
      <v-card
        ref="card"
        flat
        v-on="on"
        v-bind="attrs"
        width="100%"
        height="100%"
      >
        <StaffEventCard
          data-draft="staffCard"
          :data-draft-event-id="value.templateId"
          :data-draft-event-title="payload.title"
          :data-draft-event-date="date"
          :data-draft-event-day="payload.when.day"
          :data-draft-event-time="payload.when.time"
          :data-draft-event-duration="payload.when.duration"
          :data-draft-event-isInPerson="payload.isInPerson"
          :data-draft-event-isVirtual="payload.isVirtual"
          :data-draft-event-instructor="payload.instructor"
          :title="title"
          :duration="duration"
          :isInPerson="isInPerson"
          :isVirtual="isVirtual"
          :instructor="instructor"
          :isCancelled="isCancelled"
        />
      </v-card>
    </template>
    <StaffEventForm
      v-model="select"
      data-draft="staffForm"
      :data-draft-event-id="value.templateId"
      :data-draft-event-title="payload.title"
      :data-draft-event-date="date"
      :data-draft-event-day="payload.when.day"
      :data-draft-event-time="payload.when.time"
      :data-draft-event-duration="payload.when.duration"
      :data-draft-event-isInPerson="payload.isInPerson"
      :data-draft-event-isVirtual="payload.isVirtual"
      :data-draft-event-instructor="payload.instructor"
      :disabled="!menu"
      :staff="staff"
      :title="title"
      :duration="duration"
      :instructor="instructor"
      :originalInstructor="template.instructor"
      :isCancelled="isCancelled"
      :canRestore="canRestore"
      :canCancel="canCancel"
      :canDelete="canDelete"
      :canDeleteSeries="canDeleteSeries"
      :count="count"
      @close="reset"
      @restore="restore"
      @cancel="cancel"
      @delete="deleteInstance"
      @deleteSeries="deleteSeries"
      @change="change"
    />
  </v-menu>
  <v-card v-else ref="card" flat width="100%" height="100%">
    <StaffEventCard
      data-draft="staffCard"
      :data-draft-event-id="value.templateId"
      :data-draft-event-title="payload.title"
      :data-draft-event-date="date"
      :data-draft-event-day="payload.when.day"
      :data-draft-event-time="payload.when.time"
      :data-draft-event-duration="payload.when.duration"
      :data-draft-event-isInPerson="payload.isInPerson"
      :data-draft-event-isVirtual="payload.isVirtual"
      :data-draft-event-instructor="payload.instructor"
      :title="title"
      :duration="duration"
      :isInPerson="isInPerson"
      :isVirtual="isVirtual"
      :instructor="instructor"
      :isCancelled="isCancelled"
    />
  </v-card>
</template>

<script>
import moment from "moment";
import StaffEventCard from "./StaffEventCard";
import StaffEventForm from "./StaffEventForm";

export default {
  name: "StaffEvent",
  components: {
    StaffEventCard,
    StaffEventForm,
  },
  props: {
    range: { type: Object },
    value: { type: Object, required: true },
    staff: { type: Array },
    readonly: { type: Boolean },
  },
  data: (vm) => ({
    menu: false,
    select: vm.range ? 0 : 1,
  }),
  computed: {
    date() {
      return this.value.date
        ? moment(this.value.date).format("YYYY-MM-DD")
        : undefined;
    },
    payload() {
      return this.value.payload;
    },
    template() {
      return this.value.template ?? this.value.payload;
    },
    count() {
      if (!this.range) return undefined;

      const beg = moment(this.range.beg);
      while (beg.isoWeekday() !== this.payload.when.day) beg.add(1, "day");
      return moment(this.range.end).diff(beg, "weeks") + 1;
    },
    title() {
      return this.payload.title || this.$t("no title");
    },
    duration() {
      return this.payload.when.duration;
    },
    isInPerson() {
      return !!this.payload.isInPerson;
    },
    isVirtual() {
      return !!this.payload.isVirtual;
    },
    instructor() {
      return this.payload.instructor;
    },
    isCancelled() {
      return !!this.value.isCancelled;
    },
    canRestore() {
      return !!(
        this.value.isCancelled ||
        (this.value.date && !this.value.isInstance)
      );
    },
    canCancel() {
      return !!(this.range && !this.value.isCancelled);
    },
    canDelete() {
      return !!this.value.isInstance;
    },
    canDeleteSeries() {
      return !this.value.isInstance;
    },
  },
  methods: {
    restore() {
      this.$emit("restore");
      this.reset();
    },
    cancel() {
      this.$emit("cancel");
      this.reset();
    },
    deleteInstance() {
      this.$emit("delete");
      this.reset();
    },
    deleteSeries() {
      this.$emit("deleteSeries");
      this.reset();
    },
    change(payload) {
      switch (this.select) {
        case 0:
          this.$emit("change", payload);
          break;
        case 1:
          this.$emit("updateSeries", payload);
          break;
      }
      this.reset();
    },
    reset(v) {
      this.menu = !!v;
      this.select = this.range ? 0 : 1;
    },
  },
};
</script>
