<template>
  <div style="position: relative">
    <!-- grid -->
    <Grid
      :box-width="boxWidth"
      :box-height="boxHeight"
      :rows="rows"
      :columns="columns"
    />

    <!-- events -->
    <v-card
      ref="events"
      flat
      v-for="(event, i) in events"
      :key="event.templateId"
      :style="`position: absolute; top: ${getRow(i) * boxHeight}px; left: ${
        getColumn(i) * boxWidth + 1
      }px`"
      :width="boxWidth - 1"
      :height="boxHeight - 1"
      class="pa-2"
      :data-draft-event-row="getRow(i)"
      :data-draft-event-column="getColumn(i)"
    >
      <StaffEvent
        :readonly="readonly"
        :range="range"
        :value="event"
        :staff="staff"
        @restore="$emit('restore', event.templateId)"
        @cancel="$emit('cancel', event.templateId)"
        @delete="$emit('delete', event.templateId)"
        @change="(payload) => $emit('change', event.templateId, payload)"
        @updateSeries="
          (payload) => $emit('updateSeries', event.templateId, payload)
        "
        @deleteSeries="$emit('deleteSeries', event.templateId)"
      />
    </v-card>
  </div>
</template>

<script>
import Grid from "./Grid";
import StaffEvent from "./StaffEvent";
export default {
  name: "StaffEvents",
  components: {
    Grid,
    StaffEvent,
  },
  props: {
    readonly: { type: Boolean },
    minDay: { type: Number, required: true },
    maxDay: { type: Number, required: true },
    boxWidth: { type: Number, required: true },
    boxHeight: { type: Number, required: true },
    scheduleTimes: { type: Array, required: true },
    range: { type: Object },
    events: { type: Array, required: true },
    staff: { type: Array, required: true },
  },
  computed: {
    rows() {
      return this.scheduleTimes.length;
    },
    columns() {
      return this.maxDay - this.minDay + 1;
    },
  },
  methods: {
    getColumn(i) {
      return this.events[i].payload.when.day - this.minDay;
    },
    getRow(i) {
      const when = this.events[i].payload.when;
      const matches = this.events
        .slice(0, i)
        .map((ev) => ev.payload)
        .filter(
          (ev) => ev.when.day === when.day && ev.when.time === when.time
        ).length;
      return this.scheduleTimes.indexOf(when.time) + matches;
    },
  },
};
</script>
